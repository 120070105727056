import { Definition } from '@app/models/component';
import { BooleanColumnRenderer } from '@app/components';
import * as React from 'react';
import { TabularData } from '@app/containers';

const enabledRenderer = (component: Definition): React.ReactNode => (
  <BooleanColumnRenderer valid={component.enabled} />
);

const Components = () => (
  <TabularData
    type={Definition}
    endpoint="/components"
    query="{uuid,description,code,cost,updatedDate,enabled}"
    columns={[
      { key: 'description', label: 'Name' },
      { key: 'enabled', renderer: enabledRenderer, width: '10%' },
      { key: 'code' },
      { key: 'humanCost', label: 'Cost' },
      { key: 'humanUpdatedAt', label: 'Last Updated' },
    ]}
    filter={{
      export: true,
      placeholder:
        'Enter a code, description, or short description to search for...',
      builder: (q: string) => `(name:${q})`,
    }}
  />
);

export { Components };
export default Components;
