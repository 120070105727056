import { Panel } from '@app/models/panel';
import { Project } from '@app/models/project';
import * as React from 'react';
import { Text } from '@bespohk/uikit/components';
import classNames from 'classnames/bind';
import styles from './parts.module.css';

const cx = classNames.bind(styles);

type OwnProps = {
  project: Project;
  panel: Panel;
};

type Group = {
  [key: string]: string[];
};

const Legend = ({ panel }: OwnProps) => {
  const componentsByEquipmentCode = panel.components.reduce(
    (grouped: Group, component) => {
      const { equipmentCode, legendDescription, isSpacer, isGas } = component;
      if (isSpacer) {
        return grouped;
      }
      if (!grouped[equipmentCode]) {
        grouped[equipmentCode] = [];
      }

      const name = isGas
        ? `${legendDescription} (${panel.gpoCentre}mm centers)`
        : legendDescription;

      if (grouped[equipmentCode].indexOf(name) === -1) {
        grouped[equipmentCode].push(name);
      }

      return grouped;
    },
    {},
  );

  return (
    <div className={cx({ legend: true })}>
      {panel.isVertical && (
        <Text className={styles.strapping}>
          All centre lines between services from one row to another is{' '}
          {panel.strappingSize}mm
        </Text>
      )}
      <table
        className={cx({ table: true, outer: true })}
        cellSpacing={0}
        cellPadding={0}
      >
        <thead>
          <tr>
            <th colSpan={2}>Components</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(componentsByEquipmentCode).map((equipmentCode) => (
            <tr key={equipmentCode}>
              <td>{equipmentCode}</td>
              <td>{componentsByEquipmentCode[equipmentCode].join(', ')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { Legend };
export default Legend;
