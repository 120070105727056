import {
  Button,
  ButtonBar,
  Icons,
  Prompt,
  Text,
} from '@bespohk/uikit/components';
import { operations } from '@app/state/ducks/project/operations';
import * as React from 'react';

import { Project } from '@app/models/project';
import State from '@app/state';
import { TabularData } from '@app/containers';
import classNames from 'classnames/bind';
import styles from './projects.module.css';
import { toTitleCase } from '@bespohk/lib';
import { useOperations } from '@app/helpers/redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const cx = classNames.bind(styles);

const customerRenderer = (project: Project): string =>
  project.customer?.businessName;

const statusRenderer = (project: Project): string =>
  toTitleCase(project.status);

const Projects = () => {
  const [showPeek, setShowPeek] = React.useState<Project>(null);
  const [isCreatingTest, setIsCreatingTest] = React.useState<boolean>(false);
  const [isCreatingTestPrompt, setIsCreatingTestPrompt] =
    React.useState<boolean>(false);
  const [showDuplicate, setShowDuplicate] = React.useState<Project>(null);
  const { duplicate, listClear, createTest } = useOperations(operations);
  const history = useHistory();
  const project = useSelector((state: State) => state.project.data);

  React.useEffect(() => {
    if (project) {
      listClear();
    }
  }, [project]);

  return (
    <>
      <TabularData
        type={Project}
        endpoint="/projects"
        query="{uuid,name,salesOrderNumber,customer,contact,status,updatedDate,enabled,revision,code,priority,totalPanels,purchaseOrderNumber}"
        filter={{
          placeholder: 'Enter a project name or sales order to search...',
          builder: (q: string) => `(combined: ${q}, enabled:True)`,
        }}
        columns={[
          {
            key: 'name',
            width: '20%',
            renderer: (p: Project) => (
              <Button
                label={p.name}
                type="text"
                action={() => setShowPeek(p)}
              />
            ),
          },
          { key: 'salesOrderNumber', label: 'S/O Number' },
          { key: 'revision', width: '10%' },
          { key: 'customer', renderer: customerRenderer },
          { key: 'status', renderer: statusRenderer, width: '10%' },
          {
            key: 'humanUpdatedAt',
            label: 'Last Updated',
          },
        ]}
        actions={[
          {
            name: 'History',
            icon: Icons.History,
            callback: (item: Project) => {
              history.push(`/projects/${item.uuid}/history`);
            },
          },
        ]}
      />
      <Prompt
        className={cx({ overview: true })}
        title="Overview"
        message={
          showPeek && (
            <>
              <Text className={cx({ text: true })}>
                Project Code:{' '}
                <span className={cx({ value: true })}>{showPeek.code}</span>
              </Text>
              <Text className={cx({ text: true })}>
                Purchase Order Number:{' '}
                <span className={cx({ value: true })}>
                  {showPeek.purchaseOrderNumber}
                </span>
              </Text>
              <Text className={cx({ text: true })}>
                Priority:{' '}
                <span className={cx({ value: true })}>{showPeek.priority}</span>
              </Text>
              <Text className={cx({ text: true })}>
                Contact:{' '}
                <span className={cx({ value: true })}>
                  {showPeek.contact ? showPeek.contact.name : null}
                </span>
              </Text>
              <Text className={cx({ text: true })}>
                Total Drawings:{' '}
                <span className={cx({ value: true })}>
                  {showPeek.totalPanels ? showPeek.totalPanels : null}
                </span>
              </Text>
            </>
          )
        }
        when={!!showPeek}
        positive={{
          label: 'Duplicate',
          action: () => {
            setShowDuplicate(showPeek);
            setShowPeek(null);
          },
        }}
        negative={{ label: 'Close', action: () => setShowPeek(null) }}
      />

      <Prompt
        className={cx({ overview: true })}
        title="Duplicate project"
        message={`Are you sure you wish to duplicate ${
          showDuplicate ? showDuplicate.name : null
        }?`}
        when={!!showDuplicate}
        positive={{
          label: 'OK',
          action: () => {
            // eslint-disable-next-line
            // @ts-ignore TODO: resolve this
            duplicate(showDuplicate).then((dupe) => {
              history.push(`/projects/${dupe.uuid}`);
            });
            setShowDuplicate(null);
          },
        }}
        negative={{ label: 'Cancel', action: () => setShowDuplicate(null) }}
      />

      <Prompt
        className={cx({ overview: true })}
        title="Create test project"
        message="Are you sure you want to create a test project? This may take some time to generate, as it uses every component."
        when={!!isCreatingTestPrompt}
        positive={{
          label: 'OK',
          action: () => {
            setIsCreatingTestPrompt(false);
            setIsCreatingTest(true);
            // @ts-ignore TODO: resolve types
            createTest().then(() => {
              setIsCreatingTest(false);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
          },
        }}
        negative={{
          label: 'Cancel',
          action: () => {
            setIsCreatingTest(false);
            setIsCreatingTestPrompt(false);
          },
        }}
      />

      <ButtonBar>
        <Button
          size="small"
          disabled={isCreatingTest || isCreatingTestPrompt}
          action={() => {
            setIsCreatingTestPrompt(true);
          }}
          label={
            isCreatingTest ? 'Creating test project...' : 'Create test project'
          }
        />
      </ButtonBar>
    </>
  );
};

export { Projects };
export default Projects;
