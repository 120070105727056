import { PopRivet } from '@app/models/pop-rivet';
import * as React from 'react';
import { TabularData } from '@app/containers';

const PopRivets = () => (
  <TabularData
    type={PopRivet}
    endpoint="/pop-rivets"
    identifier="partNumber"
    columns={[{ key: 'description' }, { key: 'partNumber', width: '20%' }]}
    filter={{
      placeholder: 'Enter a part number to search for...',
      builder: (q: string) => `(partNumber: ${q})`,
    }}
  />
);

export { PopRivets };
export default PopRivets;
