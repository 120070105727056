import { Input, Prompt, Text } from '@bespohk/uikit/components';
import { operations } from '@app/state/ducks/project/operations';
import * as React from 'react';

import { Project } from '@app/models/project';
import classNames from 'classnames/bind';
import styles from './send-for-approval.module.css';
import { useOperations } from '@app/helpers/redux';

const cx = classNames.bind(styles);

type OwnProps = {
  project: Project;
  close: () => void;
};

const SendForApproval = ({ project, close }: OwnProps) => {
  const [date, setDate] = React.useState(null);
  const { sendForApproval } = useOperations(operations);

  return (
    <Prompt
      message={
        <>
          <Text className={cx({ message: true })}>
            Are you sure you'd like to send this project for approval
          </Text>
          <Input
            name="date"
            type="date"
            onChange={(_, value) => setDate(value)}
            value={date}
          />
        </>
      }
      title="Send for approval"
      when={!!project}
      positive={{
        action: () => {
          if (!date) {
            return;
          }
          sendForApproval(project, date);
          close();
        },
        label: 'Confirm',
      }}
      negative={{
        action: () => close(),
        label: 'Cancel',
      }}
    />
  );
};

export { SendForApproval };
export default SendForApproval;
