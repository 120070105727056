import { BooleanColumnRenderer } from '@app/components';
import * as React from 'react';
import { TabularData } from '@app/containers';
import User from '@app/models/user';

const statusRenderer = (user: User): React.ReactNode => (
  <BooleanColumnRenderer valid={user.isActive} />
);

type OwnProps = unknown;

const Users = (_: OwnProps) => (
  <TabularData
    type={User}
    endpoint="/users"
    filter={{
      placeholder: 'Enter a name or email to search for...',
      builder: (q: string) => `(name: ${q})`,
    }}
    columns={[
      { key: 'email' },
      { key: 'name' },
      { key: 'code' },
      { key: 'humanRoles', label: 'Roles' },
      { key: 'isActive', label: 'Enabled', renderer: statusRenderer },
      { key: 'humanUpdatedAt', label: 'Last Updated' },
    ]}
  />
);

export { Users };
export default Users;
