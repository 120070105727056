import { Route } from '@app/models/route';
import * as React from 'react';
import { TabularData } from '@app/containers';
import { BooleanColumnRenderer } from '@app/components';

const enabledRenderer = (route: Route): React.ReactNode => (
  <BooleanColumnRenderer valid={route.enabled} />
);

const Routes = () => (
  <TabularData
    type={Route}
    endpoint="/routes"
    identifier="routeId"
    columns={[
      { key: 'code' },
      { key: 'routeId', width: '20%' },
      { key: 'minutes', width: '20%' },
      { key: 'enabled', width: '20%', renderer: enabledRenderer },
    ]}
    filter={{
      placeholder: 'Enter a route id to search for...',
      builder: (q: string) => `(routeId: ${q})`,
    }}
  />
);

export { Routes };
export default Routes;
