import Strapping from '@app/models/strapping';
import * as React from 'react';
import { TabularData } from '@app/containers';
import { BooleanColumnRenderer } from '@app/components';

const enabledRenderer = (strapping: Strapping): React.ReactNode => (
  <BooleanColumnRenderer valid={strapping.enabled} />
);

const Strappings = () => (
  <TabularData
    type={Strapping}
    endpoint="/strappings"
    identifier="partNumber"
    columns={[
      { key: 'description' },
      { key: 'partNumber', width: '20%' },
      { key: 'enabled', renderer: enabledRenderer },
    ]}
    filter={{
      placeholder: 'Enter a part number to search for...',
      builder: (q: string) => `(partNumber: ${q})`,
    }}
  />
);

export { Strappings };
export default Strappings;
